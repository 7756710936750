import {
    FeatureBlockSection,
    FeatureBlockContent
} from '../../FeatureBlockElements';
import { TRANSLATION_COMPONENTS } from '../../../constants/constants';
import HBText from '../../honeybook-ui/HBText/HBText';
import { useTranslation } from 'react-i18next';

import { memo } from 'react';
import { Trans } from 'react-i18next';
import QuoteIcon from '../../../svg/quote.inline.svg';
import HBMediaElement from '../../honeybook-ui/HBMediaElement/HBMediaElement';

import './testimonial-carousel.scss';

const TestimonialCard = ({ quote, name, jobTitle }) => {
    return (
        <div className="testimonial-carousel__testimonial">
            <QuoteIcon className="testimonial-carousel__testimonial__quote-icon" />
            <HBText
                tag="p"
                type="body-l"
                customClass="testimonial-carousel__testimonial__quote"
            >
                {quote}
            </HBText>
            <HBText
                tag="p"
                type="body-l"
                customClass="testimonial-carousel__testimonial__name"
            >
                {name}
            </HBText>
            <HBText
                tag="p"
                type="body-l"
                customClass="testimonial-carousel__testimonial__job-title"
            >
                {jobTitle}
            </HBText>
        </div>
    );
};

const testimonials = [
    {
        name: 'Dee T.',
        transKey: 'testimonialCardsBlock.testimonials.dee',
        imageUrl: 'marketing_site/home_page/dee.png'
    }
];
const TestimonialCarousel = () => {
    const { t } = useTranslation();
    return (
        <FeatureBlockSection customClass="testimonial-carousel">
            <FeatureBlockContent>
                <HBText
                    tag="h1"
                    type="title-l"
                    customClass="testimonial-carousel__title"
                >
                    <Trans
                        i18nKey="testimonialCarousel.title"
                        components={TRANSLATION_COMPONENTS}
                    />
                </HBText>
                {testimonials.map(({ transKey, name, imageUrl }) => (
                    <div>
                        <TestimonialCard
                            key={transKey}
                            quote={t(`${transKey}.quote`)}
                            name={name}
                            jobTitle={t(`${transKey}.jobTitle`)}
                        />
                        <HBMediaElement
                            customClass="testimonial-carousel__photo"
                            name={imageUrl}
                        />
                    </div>
                ))}
                <HBMediaElement
                    name="marketing_site/home_page/star-dark.png"
                    customClass="testimonial-carousel__star testimonial-carousel__star1"
                />
                <HBMediaElement
                    name="marketing_site/home_page/star-dark.png"
                    customClass="testimonial-carousel__star testimonial-carousel__star2"
                />
                <HBMediaElement
                    name="marketing_site/home_page/star-light.png"
                    customClass="testimonial-carousel__star testimonial-carousel__star3"
                />
            </FeatureBlockContent>
        </FeatureBlockSection>
    );
};

export default memo(TestimonialCarousel);
