export const TRANSLATION_COMPONENTS = {
    sup: <sup />,
    small: <small />,
    p: <p />,
    ul: <ul />,
    li: <li />,
    ol: <ol />,
    strong: <strong />,
    span: <span />,
    a: <a />,
    em: <em />,
    br: <br />
};

export const CLICK_START_TRIAL_ADDITIONAL_DATA_STORAGE_KEY = 'additionalData';
export const CURRENT_REFERER_STORAGE_KEY = 'currentReferrer';

const EXCLUSION_PATHS = [
    '/',
    '/about',
    '/press',
    '/contact-us',
    '/referrals',
    '/404',
    '/careers',
    '/invites',
    '/careers',
    '/educators',
    '/product-education',
    '/activation',
    '/unsupported',
    '/terms/terms-of-service',
    '/terms/terms-of-service-original',
    '/terms/privacy',
    '/terms/privacy-original',
    '/feedback',
    '/getstarted-honeybook',
    '/client-relationships'
];

export const PROMO_POPUP_EXCLUSION_PATHS = [
    ...EXCLUSION_PATHS,
    '/',
    '/getstarted'
];

export const PROMO_BANNER_EXCLUSION_PATHS = [...EXCLUSION_PATHS];

export const VARIANT_OPTIONS = ['a', 'b', 'c', 'd', 'e', 'f'];

export const INITIAL_DOLLAR_AMOUNT = 14123;

export const CAPTERRA_REVIEWS = {
    rating: 4.8,
    totalReviews: 598,
    bestRating: '5',
    worstRating: '1',
    url: 'https://www.capterra.com/p/162588/HoneyBook/'
};
export const G2_REVIEWS = {
    rating: 4.5,
    totalReviews: 158,
    bestRating: '5',
    worstRating: '1',
    url: 'https://www.g2.com/products/honeybook/reviews'
};

export const INITIAL_EMAIL_SIGNATURE_DISPLAY_VALUE = {
    imageUrl:
        'https://res.cloudinary.com/honeybook/image/upload/v1693999725/marketing_site/email-signature/signature-image-empty-state.png',
    name: 'Gunther Anders',
    jobTitle: 'Founder',
    company: 'Gunther’s Barista Academy',
    email: 'gunther@gunthersbaristas.com',
    address: '199 Lafayette St, New York, NY 10012, USA',
    phone: '210-559-2358',
    website: 'https://www.gunthersbaristas.com',
    facebookUrl: '_',
    twitterUrl: '_',
    linkedinUrl: '_',
    instagramUrl: '_',
    pinterestUrl: '_',
    youtubeUrl: '_',
    selectedTemplateName: 'conservative'
};

export const REFERRAL_DISCOUNT_KEY = 'referralDiscount';
