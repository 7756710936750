import { memo, ReactNode } from 'react';
import HBText from '../honeybook-ui/HBText/HBText';
import { TButtonStyle } from '../honeybook-ui/HBButton/HBButton';
import {
    FeatureBlockSection,
    FeatureBlockContent
} from '../FeatureBlockElements';
import RegistrationForm from '../RegistrationForm/RegistrationForm';

import './full-bleed-block.scss';

interface Props {
    title: string | ReactNode;
    customClass?: string;
    backgroundImage: string;
    showCTA?: boolean;
    source?: string;
    children?: ReactNode;
    buttonStyle?: TButtonStyle;
}
const FullBleedBlock = ({
    title,
    customClass,
    backgroundImage,
    showCTA,
    source = 'full bleed block',
    children,
    buttonStyle
}: Props) => {
    const backgroundStyle = {
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat'
    };

    return (
        <div style={backgroundStyle}>
            <FeatureBlockSection
                customClass={`full-bleed-block ${customClass}`}
            >
                <FeatureBlockContent>
                    <HBText
                        tag="h1"
                        type="title-l"
                        customClass="full-bleed-block__title full-bleed-block__title--1"
                    >
                        {title}
                    </HBText>
                    {showCTA && (
                        <RegistrationForm
                            source={source}
                            size="large"
                            buttonStyle={buttonStyle}
                        />
                    )}
                    {children}
                </FeatureBlockContent>
            </FeatureBlockSection>
        </div>
    );
};

export default memo(FullBleedBlock);
